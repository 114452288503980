<template>
  <div>
    <v-dialog v-model="dialog2" max-width="600px" persistent>
      <v-card class="pa-3" v-if="!metodoContatto">
        <h1 class="title mb-4" style="color: red">
          Nessuna modalità di contatto selezionata
        </h1>
        <p class="mb-5">Per favore, seleziona una modalità di contatto</p>
        <v-btn color="primary" @click="dialog2 = !dialog2">Ok</v-btn>
      </v-card>
      <v-card class="pa-3" v-else-if="metodoContatto == 'telefono' && !combinazioni">
        <h1 class="title mb-4" style="color: red">
          Opzioni mancanti o incomplete
        </h1>
        <p class="mb-5">
          Non è stata selezionata alcuna opzione sull'esito della telefonata
          oppure le informazioni sono incomplete.
        </p>
        <v-btn color="primary" @click="dialog2 = !dialog2">Chiudi</v-btn>
      </v-card>
      <v-card v-else-if="metodoContatto == 'telefono' && combinazioni && candidato.mail
      " class="text-center">
        <v-card-text>
          <v-container>
            <div class="text-h5" style="color: #1f4b6b">
              Conferma di aver contattato <br />
              {{ candidato.candidato }} <br />tramite
              {{ metodoContatto }}
            </div>
            <section>
              <div class="container pb-0" v-if="metodoContatto == 'telefono'">
                <div v-if="rifiuta" class="pb-3 font-weight-bold h5" style="color: darkred">
                  Scrivi la motivazione per cui vuoi eliminare il candidato
                </div>
                <div v-else class="pb-3 font-weight-bold h5" style="color: #1f4b6b">
                  Descrivere brevemente il colloquio con il candidato
                </div>
                <v-textarea rows="4" outlined v-model="nota_primo_contatto"></v-textarea>
              </div>
              <v-divider class="mt-0"></v-divider>
              <v-row align-content="center" align="center">

                <v-checkbox v-if="metodoContatto == 'telefono'" style="font-weight: 600; color: darkred !important"
                  color="red darken-3" v-model="confermaTelefono">
                  <template v-slot:label>
                    <span v-if="rifiuta" style="color: darkred !important">Confermando elimini il contato
                      dall'elenco</span>

                    <span v-else-if="!rifiuta">Conferma di aver contattato il candidato</span>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <small>Contattato in data e
                    ora<br />
                    <strong>{{ new Date().toLocaleString("it-IT") }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <small>
                    Operatore<br />
                    <strong>{{ user.Nominativo }}</strong>
                  </small>
                </v-col>
              </v-row>
            </section>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mx-2" color="danger" @click="
            dialog2 = false;
          resetModaleConferme();
          " variant="ghost"><i class="fas fa-times"></i>&nbsp; Annulla
          </CButton>
          <v-btn color="#1f4b6b" dark @click="(dialog2 = false), lavoraContatto()"
            :class="{ disabled_input: isSaveButtonDisabled }"><i class="far fa-save"></i> &nbsp;
            {{ !rifiuta ? "Salva" : "Elimina" }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="metodoContatto == 'telefono' && combinazioni && !candidato.mail
      " class="text-center">
        <h1 class="title mb-4" style="color: red">Indirizzo email assente</h1>
        <p class="mb-5">
          Non è possibile proseguire se non si inserisce un'indirizzo email
          valido nella scheda del candidato.
        </p>
        <v-btn color="primary" @click="dialog2 = !dialog2">Chiudi</v-btn>
      </v-card>
      <v-card v-else-if="metodoContatto != 'telefono'" class="text-center">
        <v-card-text>
          <v-container>
            <div class="text-h5" style="color: #1f4b6b">
              Conferma di aver contattato <br />
              {{ candidato.candidato }} <br />tramite
              {{ metodoContatto }}
            </div>
            <section>
              <div class="container pb-0" v-if="metodoContatto == 'telefono'">
                <div class="pb-3 font-weight-bold h5" style="color: #1f4b6b">
                  Descrivere brevemente il colloquio con il candidato
                </div>
                <v-textarea rows="4" outlined v-model="nota_primo_contatto"></v-textarea>
              </div>



              <v-divider class="mt-0"></v-divider>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <small>
                    Contattato in data e ora<br />
                    <strong>{{ new Date().toLocaleString("it-IT") }}</strong>
                  </small>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <small>
                    Operatore<br />
                    <strong>{{ user.Nominativo }}</strong>
                  </small>
                </v-col>
              </v-row>
            </section>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mx-2" color="danger" @click="
            dialog2 = false;
          resetModaleConferme();
          " variant="ghost"><i class="fas fa-times"></i>&nbsp; Annulla
          </CButton>
          <v-btn color="#1f4b6b" dark @click="(dialog2 = false), lavoraContatto()"
            :class="{ disabled_input: isSaveButtonDisabled }"><i class="far fa-save"></i> &nbsp; Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#1f4b6b" dark v-bind="attrs" v-on="on">
          <i class="fas fa-user-edit"> </i> &nbsp;Contatta
        </v-btn>
      </template>
      <v-card>
        <v-container>
          <v-toolbar dark color="#1f4b6b">
            <v-btn icon dark @click="
              dialog = false;
            resetBeforeClose();
            ">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            Lavorazione:&nbsp; {{ candidato.candidato }} &nbsp;
            <small>
              contatto inserito in data: &nbsp;{{
                candidato.data_ins | formatDate
              }}</small>
            <v-spacer></v-spacer>
            <v-alert dense elevation="4" outlined text type="warning" v-if="anaIncompleta">
              Attenzione la scheda anagrafica è incompleta</v-alert>
            <v-spacer></v-spacer>

          </v-toolbar>
          <scheda :candidato="candidato" @updateCandidato="updateCandidato"></scheda>
          <v-divider></v-divider>

          <div>
            <section id="modalita_contatto">
              <v-row>
                <v-col cols="12" md="6">
                  <h3 style="color: #1f4b6b">
                    <strong>Modalità di contatto:</strong>
                  </h3>
                  <small>Seleziona il metodo utilizzato per contattare il
                    candidato</small>

                  <v-col cols="12" sm="6" md="6">
                    <v-radio-group v-model="metodoContatto" row>
                      <v-radio label="Telefono" value="telefono"
                        :disabled="!candidato.telefono && !candidato.cell"></v-radio>
                      <v-radio label="Email" value="email" :disabled="!candidato.mail"></v-radio>
                    </v-radio-group>
                  </v-col>

                </v-col>

              </v-row>
            </section>
            <v-divider></v-divider>
            <v-tooltip top :color="metodoContatto !== 'telefono' ? 'warning' : 'transparent'">
              <template v-slot:activator="{ on, attrs }">
                <section v-bind="attrs" v-on="on" id="opzioni_contatto">
                  <div :class="{
                    disabled_input:
                      !metodoContatto || metodoContatto != 'telefono',
                  }">
                    <h3 style="color: #1f4b6b">
                      <strong>Esito del contatto:</strong>
                    </h3>

                    <v-row>
                      <v-col cols="12" sm="4" md="4" :class="{
                        disabled_input: rifiuta,
                      }">
                        <v-checkbox style="font-weight: 600; color: #1f4b6b !important" v-model="accetta"
                          :label="'Accetta / Interessato'"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" :class="{
                        disabled_input: accetta,
                      }">
                        <v-checkbox style="font-weight: 600; color: #1f4b6b !important" v-model="rifiuta"
                          color="red darken-3" :label="'Rifiuta / Non interessato'"></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </section>
              </template>
              <span v-if="metodoContatto !== 'telefono'">Disponibili solo per contatto telefonico</span>
            </v-tooltip>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" outlined @click="
              dialog = false;
            resetBeforeClose();
            ">
              <i class="fas fa-times"></i>&nbsp; Chiudi
            </v-btn>
            <v-btn color="#1f4b6b" dark @click="dialog2 = true"><i class="fas fa-save fa-2x"></i> &nbsp; Salva
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import axios from "axios";
import scheda from "./Scheda.vue";
export default {
  name: "LavorazioneEnergia",
  components: {
    scheda,
  },
  props: ["step", "itemId", "candidato"],

  watch: {
    metodoContatto() {
      this.accetta = false;
      this.rifiuta = false;
    },

  },
  data() {
    return {
      scadenza: "waiting",
      forzaChiamata: false,
      today: new Date().toISOString().substr(0, 10),
      dialog: false,
      dialog2: false,
      confermaMail: false,
      confermaTelefono: false,
      nota_primo_contatto: "",
      dataChiamata: null,
      oraChiamata: null,
      metodoContatto: null,
      rifiuta: false,
      accetta: false,
      user: JSON.parse(localStorage.getItem("chisono_data")),
    };
  },
  methods: {

    resetBeforeClose() {
      this.scadenza = "waiting";
      this.forzaChiamata = false;
      this.metodoContatto = null;
      this.accetta = false;
      this.rifiuta = false;


    },
    resetModaleConferme() {
      // reset dei checkbox

      this.confermaTelefono = false;
      this.confermaMail = false;


      // reset dei campi compilabili
      this.nota_primo_contatto = null;

    },
    async lavoraContatto() {
      // Salvataggio ed avanzamento di stato
      // preparo i parametri da passare al ws
      let params = {
        contatto: this.candidato,
        notePrimoContatto: this.nota_primo_contatto || "",
        canalePrimoContatto: this.metodoContatto,
        dataChiamata: this.dataChiamata,
        oraChiamata: this.oraChiamata,
        accetta: this.accetta,
        rifiuta: this.rifiuta,
        utente: this.user,
      };
      try {
        await axios
          .post(
            this.$custom_json.base_url +
            this.$custom_json.api_url +
            this.$custom_json.crm.lavoraContattoEnergy,
            params
          )
          .then((response) => {
            var message = response.data.message;
            switch (response.data.esito) {
              case "OK":
                this.$alert(message, "OK", "success");
                this.$emit("aggiorna_grid", this.step);
                break;
              case "KO":
                this.$alert(message, "Attenzione", "warning");
                break;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    updateCandidato() {
      this.$emit("aggiorna_grid", this.step);
    },
  },
  computed: {
    anaIncompleta() {
      if (
        this.candidato.tipologia == "PF" &&
        this.candidato.cf != "" &&
        (this.candidato.telefono != null || this.candidato.cell != null) &&
        this.candidato.mail
      ) {
        return false;
      }
      if (
        this.candidato.tipologia == "PG" &&
        this.candidato.piva != "" &&
        (this.candidato.telefono != null || this.candidato.cell != null) &&
        this.candidato.mail
      ) {
        return false;
      }
      return true;
    },
    isSaveButtonDisabled() {

      if (
        this.metodoContatto == "email" ||
        this.metodoContatto == "forza_mail"
      ) {
        return !this.confermaMail;
      }
      if (this.metodoContatto === "telefono") {

        if (this.nota_primo_contatto && this.confermaTelefono) {
          return false;
        }

        return true;
      }

      return true;
    },
    combinazioni() {
      if (this.metodoContatto == "telefono") {
        if (this.accetta) {
          return true;
        }

        if (this.rifiuta) {
          return true;
        }

      }

      return false;
    },
  },
};
</script>
<style scoped>
.disabled_input {
  pointer-events: none;
  opacity: 0.5;
}
</style>
