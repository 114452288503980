var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"toggle_side"},[_c('button',{staticClass:"btn_sidebar",on:{"click":_vm.toggleSidebar}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarHidden),expression:"sidebarHidden"}]},[_c('i',{staticClass:"fas fa-bars"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sidebarHidden),expression:"!sidebarHidden"}]},[_c('i',{staticClass:"fas fa-angle-double-left"})])])]),(_vm.userCRMInfo.idRuolo == 6)?_c('CNav',{staticClass:"sidebar",attrs:{"vertical":""}},[_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('new_segnalatore')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Aggiungi candidature',
          placement: 'right',
        }),expression:"{\n          content: 'Aggiungi candidature',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-user-plus"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Aggiungi candidato")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('utenti_attivi')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Utenti attivi',
          placement: 'right',
        }),expression:"{\n          content: 'Utenti attivi',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-users"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Utenti attivati")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('ricerca')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Ricerca',
          placement: 'right',
        }),expression:"{\n          content: 'Ricerca',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-search"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Ricerca")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn_deleted",on:{"click":function($event){return _vm.buttonClicked('eliminati')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Eliminati',
          placement: 'right',
        }),expression:"{\n          content: 'Eliminati',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico_deleted"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Rifiutati")])])])],1):(_vm.userCRMInfo.idRuolo == 8)?_c('CNav',{staticClass:"sidebar",attrs:{"vertical":""}},[_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('primo_contatto_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Primo contatto',
          placement: 'right',
        }),expression:"{\n          content: 'Primo contatto',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-phone-volume"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Da contattare")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('formazione_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Formazione',
          placement: 'right',
        }),expression:"{\n          content: 'Formazione',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-chalkboard-teacher"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Formazione")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('attivazione_account_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Attivazione account',
          placement: 'right',
        }),expression:"{\n          content: 'Attivazione account',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-user-check"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Attivazione account")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('utenti_attivi_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Utenti attivi',
          placement: 'right',
        }),expression:"{\n          content: 'Utenti attivi',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-users"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Utenti attivati")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('ricerca_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Ricerca',
          placement: 'right',
        }),expression:"{\n          content: 'Ricerca',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-search"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Ricerca")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn_deleted",on:{"click":function($event){return _vm.buttonClicked('eliminati_energia')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Eliminati',
          placement: 'right',
        }),expression:"{\n          content: 'Eliminati',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico_deleted"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Rifiutati")])])])],1):(_vm.userCRMInfo.idRuolo != 6 && _vm.userCRMInfo.idRuolo != 8)?_c('CNav',{staticClass:"sidebar",attrs:{"vertical":""}},[_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('new')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Aggiungi candidature',
          placement: 'right',
        }),expression:"{\n          content: 'Aggiungi candidature',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-user-plus"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Aggiungi candidato")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('primo_contatto')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Primo contatto',
          placement: 'right',
        }),expression:"{\n          content: 'Primo contatto',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-people-arrows"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Primo contatto")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('social')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Social / Email',
          placement: 'right',
        }),expression:"{\n          content: 'Social / Email',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-share-alt"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Inviti Social / Email")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('webinar')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Mini webinar',
          placement: 'right',
        }),expression:"{\n          content: 'Mini webinar',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-desktop"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Mini webinar")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('solleciti')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Chiamate di cortesia',
          placement: 'right',
        }),expression:"{\n          content: 'Chiamate di cortesia',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-phone-volume"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Chiamate di cortesia")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('registrazione_documentazione')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Registrazione documenti',
          placement: 'right',
        }),expression:"{\n          content: 'Registrazione documenti',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-file-signature"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Registrazione documenti")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('formazione')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Formazione',
          placement: 'right',
        }),expression:"{\n          content: 'Formazione',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-chalkboard-teacher"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Formazione")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('attivazione_account')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Attivazione account',
          placement: 'right',
        }),expression:"{\n          content: 'Attivazione account',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-user-check"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Attivazione account")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('utenti_attivi')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Utenti attivi',
          placement: 'right',
        }),expression:"{\n          content: 'Utenti attivi',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-users"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Utenti attivati")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn",on:{"click":function($event){return _vm.buttonClicked('ricerca')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Ricerca',
          placement: 'right',
        }),expression:"{\n          content: 'Ricerca',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico"},[_c('i',{staticClass:"fas fa-search"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Ricerca")])])]),_c('CNavItem',[_c('div',{staticClass:"side_btn_deleted",on:{"click":function($event){return _vm.buttonClicked('eliminati')}}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Eliminati',
          placement: 'right',
        }),expression:"{\n          content: 'Eliminati',\n          placement: 'right',\n        }"}],staticClass:"side_btn_ico_deleted"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"side_desc",class:{ 'd-none': _vm.sidebarHidden }},[_vm._v("Rifiutati")])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }